/**
 * @file VideoPlayer.js
 * @created_date Thursday, December 21, 2022
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description The VideoPlayer component is a React component that renders a video player with various features such as video playback, comments, and annotations. It interacts with an external video player library (VideoJS) to handle video playback and provides additional functionality on top of it.
 *<br/>Breakdown of the code - {@tutorial VideoPlayer}
 * @module VideoPlayer
 *
 **/

import React, { act, useEffect, useState } from "react";

// This imports the functional component from the previous sample.
import VideoJS from "./VideoJS";

import "videojs-logo";
import "videojs-logo/dist/videojs-logo.css";
import mediMESH_small_logo from "../../assets/img/workflow_lib/mediMESH-Icon.svg";
import player_poster from "../../assets/img/player/player_poster.jpg";
import PlayerSidebar from "./PlayerSidebar";

import userImage from "../../assets/img/normal_control_icon_user_account.svg";
import MiscFunctionsLocal from "../../helpers/MiscFunctions";
import { MiscFunctions } from 'helper-functions-package';
import WorkflowBar from "./WorkflowBar";
import Segments from "../../services/SegmentsServices";
import Segment from "../../services/SegmentServices";
import Video from "../../services/VideoService";
import userAPI from "../../api/userAPI";
import videoAPI from "../../api/videoAPI";
import { useNavigate } from "react-router";
import QuickOverviewPopupsContainer from "../IntroductionPopups/QuickOverviewPopupsContainer";
import Ressources from "../../services/RessourcesServices";
import TimelineGlobal from "./TimelineGlobal";
import { getAuthAccessObject } from "../../utils/tokenStorage";
import authAPI from "../../api/authAPI";
import CommentAnnotationContainer from "../CommentContainer/CommentAnnotationContainer";
import ToastMessages from "../../helpers/ToastMessages";
import { ToastContainer } from "react-toastify";

class propertyCommentAnnotation {
  constructor(id, x, y, category, state) {
    this.id = id;
    this.left = x;
    this.top = y;
    this.category = category;
    this.status = state;
  }
  changeState = (state) => {
    this.status = state;
  };
}

const VideoPlayer = (props) => {
  const loginStatus = getAuthAccessObject();
  if (MiscFunctions.isNull(loginStatus)) {
    authAPI.logout();
  }

  const playerRef = React.useRef(null);
  const containerAnnotationRef = React.useRef(null);

  // State to manage the Video-URL
  const [getVideoURL, setVideoURL] = useState("");
  // State to save the Videscaptions
  const [passVideoCaption, setPassVideoCaption] = useState({});
  // State to capture if the video is currently loading
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  // State to manage the Spinner-symbol
  const [isVideoLoadingSpinner, setIsVideoLoadingSpinner] = useState(true);
  const [UpdateSegments, setUpdateSegments] = useState(true);
  // State to capture the annotations in the video-player
  const [propertyCommentAnnotationArray, setpropertyCommentAnnotationArray] =
    useState([]);
  // State to manage the current comment in creation

  // State to capture the selected categories inside the comment-creation-card
  const [filterCategories, setfilterCategories] = useState({});

  const [UserObject, setUserObject] = useState({});
  const [VideoObject, setVideoObject] = useState(null);
  const [CompanyUserObject, setCompanyUserObject] = useState([]);
  const mainVideoID = props.workflow.getVideoID;

  // State to capture the position of the progress-bar
  const [ProgressBarPosition, setProgressBarPosition] = useState({
    pos: 0,
    time: "0.00",
  });

  const [deleteComment, setDeleteComment] = useState(false);
  const [deleteCommentId, setDeleteCommentId] = useState(null);
  const [updateMediaFile, setUpdateMediaFile] = useState(false);
  const [commentUpdated, setCommentUpdated] = useState(false);
  const navigate = useNavigate();
  const [toastMessage, setToastMessage] = useState("");
  const [ressourcesUpdate, setRessourcesUpdate] = useState(false);

  const [user, setUser] = useState(null);
  useEffect(() => {
    if (props.patientVideo === false) {
      videoAPI
        .getVideoById(mainVideoID)
        .then((result) => {
          setVideoURL(result.media);
          setVideoObject(new Video(result));
        })
        .finally(() => {
          if (isVideoLoadingSpinner) {
            setIsVideoLoading(true);
            setIsVideoLoadingSpinner(false);
          }
        });
    } else {
      setIsVideoLoading(true);
    }
  }, [getVideoURL, ressourcesUpdate]);

  const videoJsOptions = {
    preload: true,
    controls: true,
    responsive: true,
    bigPlayButton: false,
    fluid: true,
    poster: player_poster,
    inactivityTimeout: 0,
    sources: [
      {
        src:
          props.patientVideo === true
            ? props.workflow.getSelectedVideo[0].media
            : getVideoURL,
        type: "video/mp4",
      },
    ],
    controlBar: {
      fullscreenToggle: false,
      pictureInPictureToggle: false,
      remainingTimeDisplay: false,
      volumePanel: true,
      currentTimeDisplay: false,
      timeDivider: false,
      durationDisplay: false,
    },
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    let logoURL = "";
    if (props.patientVideo === false) logoURL = "/workflow_library";
    player
      .logo({
        image: mediMESH_small_logo,
        width: 44,
        height: 42,
        fadeDelay: null,
        position: "top-left",
        url: logoURL,
      })
      .show();

    if (props.PlayerSidebar === true) {
      userAPI
        .getUserObject()
        .then(async (res) => {
          if (res !== false && !MiscFunctions.isUndefined(res)) {
            let User = MiscFunctionsLocal.createUserObject(await res);
            setUserObject(User);
            return User;
          }
          return null;
        })
        .then(async (res) => {
          if (!MiscFunctions.isNull(res.UserCompany)) {
            userAPI.getCompanyUsers().then(async (res) => {
              if (
                !MiscFunctions.isUndefined(res) &&
                !MiscFunctions.isNull(res) &&
                res.length > 0
              ) {
                let userlist = [];
                res.map((elem, i) => {
                  userlist.push({
                    id: elem.id.id,
                    display: elem.firstname + " " + elem.lastname,
                    avatar: !MiscFunctions.isNull(elem.avatar)
                      ? elem.avatar
                      : userImage,
                    type: "user",
                    filterState: false,
                  });
                });
                setCompanyUserObject(userlist);
              }
            });
          } else {
            //Just Add the User
            let userlist = [
              {
                id: res.UserID,
                display: res.display,
                avatar: res.avatar,
                type: "user",
                filterState: false,
              },
            ];
            setCompanyUserObject(userlist);
          }
        });

      videoAPI
        .getVideoInteractionsById(mainVideoID)
        .then(async (res) => {
          // Proper encoding to ensure german umlauts
          const encoder = new TextEncoder();
          const data = encoder.encode(res);
          // Added an ID and the kind to the track
          const track = await playerRef.current.addRemoteTextTrack(
            {
              kind: "metadata",
              src: `data:text/vtt;base64,${window.btoa(
                new Uint8Array(data).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  ""
                )
              )}`,
              srclang: "en",
              label: "English",
              id: "interactions",
            },
            true
          );

          // Note: Consider to use loadmetadata-callback of the player
          track.addEventListener("load", function () {
            loadTextTracks("interactions", false);
          });
        })
        .finally(() => {
          videoAPI.getVideoProccessById(mainVideoID).then(async (res) => {
            // Added an ID and the kind to the track
            const encoder = new TextEncoder();
            const data = encoder.encode(res);
            await player.addRemoteTextTrack(
              {
                kind: "metadata",
                src: `data:text/vtt;base64,${window.btoa(
                  new Uint8Array(data).reduce(
                    (data, byte) => data + String.fromCharCode(byte),
                    ""
                  )
                )}`,
                srclang: "en",
                label: "English",
                id: "process",
              },
              true
            );
          });

          var seekBar = player.controlBar.progressControl.seekBar;
          seekBar.on("mousemove", function (event) {
            var mouseTime =
              player.duration() * seekBar.calculateDistance(event);
            const mouesDisplay =
              document.getElementsByClassName("vjs-mouse-display")[0];
            if (parseInt(mouseTime) === parseInt(player.currentTime())) {
              mouesDisplay.classList.add("vjs-mouse-display-hover");
            }
          });
        });
    }
    player.on("timeupdate", function (e) {
      let elem = {
        pos: player.currentTime() / player.duration(),
        time: (Math.round(player.currentTime() * 100) / 100)
          .toFixed(2)
          .toString(),
      };
      setProgressBarPosition(elem);
    });

    // You can handle player events here, for example:
    player.on("waiting", () => {
      console.log("player is waiting");
    });

    player.on("dispose", () => {
      console.log("player will dispose");
    });
    player.ready(() => {
      let getLogo = document.querySelector(".vjs-logo-content");

      if (!MiscFunctions.isNull(getLogo) && props.patientVideo === false && !props.redirectByRateMy) {
        getLogo.addEventListener("click", function (event) {
          event.preventDefault();
          navigate("/workflow_library");
        });
      }
    });
  };

  // Function will update the Texttracks
  const updateTextTracks = () => {
    videoAPI.getVideoInteractionsById(mainVideoID).then((res) => {
      let trackDel = playerRef.current.textTracks_.tracks_.find(
        (o) => o.id === "interactions"
      );
      playerRef.current.removeRemoteTextTrack(trackDel);

      const encoder = new TextEncoder();
      const data = encoder.encode(res);

      const track = playerRef.current.addRemoteTextTrack(
        {
          kind: "metadata",
          src: `data:text/vtt;base64,${window.btoa(
            new Uint8Array(data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          )}`,
          srclang: "en",
          label: "English",
          id: "interactions",
        },
        true
      );
      track.addEventListener("load", function () {
        loadTextTracks("interactions", true);
      });
    });
  };

  const [addComment, setAddComment] = useState(null);

  useEffect(() => { getUser() }, []);
  const getUser = async() => {
    const data = await userAPI.getUserObject();
    setUser(data);
  }
  function loadCues(actCue) {
    if (actCue.cues_.length > 0) {
      const AnnotationArray = [];

      actCue.cues_.forEach((el) => {
        let dummy;
        dummy = JSON.parse(el.text);
        if (dummy.id !== undefined && ((dummy.visibility === "public" && user.company.id === dummy.company.id) || (dummy.visibility === "private" && user.id.id === dummy.user.id))) {
          try {
            let pos = dummy.position;
            let category = dummy.category[0].type;
            AnnotationArray.push(
              new propertyCommentAnnotation(
                dummy.id,
                pos[0] * 100 + "%",
                pos[1] * 100 + "%",
                category,
                true
              )
            );
          } catch (error) {
            console.log(error);
          }
        }
      });
      setpropertyCommentAnnotationArray(AnnotationArray);
    } else {
      const AnnotationArray = [];
      setpropertyCommentAnnotationArray(AnnotationArray);
    }
  }

  const handleSlowPause = () => {
    if (playerRef.current) {
      const decrementRate = () => {
        const currentRate = playerRef.current.playbackRate();
        const currentVolume = playerRef.current.volume();

        if (currentRate > 0.1) {
          playerRef.current.playbackRate(currentRate - 0.05); // Decrease playback rate
        }

        if (currentVolume > 0.0) {
          playerRef.current.volume(Math.max(0, currentVolume - 0.05)); // Decrease volume
        }

        if (currentRate > 0.1 || currentVolume > 0.0) {
          setTimeout(decrementRate, 100); // Set timeout for smooth transition
        } else {
          playerRef.current.pause(); // Pause the video
          playerRef.current.playbackRate(1); // Reset playback rate
          playerRef.current.volume(1); // Reset volume for next play
        }
      };
      decrementRate();
    }
  };

  function loadTextTracks(tag, init) {
    const cueList = playerRef.current
      .textTracks()
      .tracks_.find((o) => o.id === tag).cues_;
    // Added callback for the Cue-Events
    let track = playerRef.current
      .textTracks()
      .tracks_.find((o) => o.id === tag);
    let actCue = track.activeCues;

    track.oncuechange = () => {
      loadCues(actCue);

      if (actCue.cues_.length > 0) {
        actCue.cues_.forEach((el) => {
          const toJson = JSON.parse(el.text);
          if (
            toJson.id !== undefined &&
            toJson.commentSettings !== null &&
            toJson.commentSettings.stopWhenReached === true
          ) {
            handleSlowPause();
          }
        });
      }
    };

    let allTextTracks = [];
    cueList.forEach((el, i) => {
      let texttrack = JSON.parse(el.text);
      allTextTracks.push(texttrack);
    });

    loadCues(actCue);
    setPassVideoCaption(allTextTracks);
  }

  const ToggleUpdate = () => {
    setUpdateSegments(!UpdateSegments);
  };

  const [showIntroPopup, setShowIntroPoup] = useState(
    props.QuickOverviewPopups ? true : false
  );
  function handleCloseIntroPopup(state) {
    setShowIntroPoup(state);
  }
  function handleOpenIntroPopup() {
    setShowIntroPoup(true);
  }

  const [ressources, setRessources] = useState([]);
  const [persons, setPersons] = useState([]);
  const [personsGroup, setPersonsGroup] = useState([]);
  useEffect(() => {
    if (
      !MiscFunctions.isNull(VideoObject) ||
      !MiscFunctions.isNull(mainVideoID)
    ) {
      videoAPI
        .getVideoRessourcesById(
          !MiscFunctions.isNull(VideoObject)
            ? VideoObject.getVideoID
            : mainVideoID
        )
        .then((response) => {
          let ressources = response;
          setRessources(ressources);
          const Resources = new Ressources(ressources);
          let persons = Resources.getPersons;
          let personsGroup = Resources.getPersonGroup;
          setPersons(persons);
          setPersonsGroup(personsGroup);
        });
    }
  }, [VideoObject]);

  const workflowParameters = {
    person: persons,
    persongroup: personsGroup,
  };

  const [WorkflowPhases, setWorkflowPhases] = useState();
  const [WorkflowTasks, setWorkflowTasks] = useState();
  // Fetch Phases for allocating to the related compnents (Sidebar, Workflowbar, ...)
  useEffect(() => {
    setfilterCategories(props.workflow.getCategories);

    const videoID = mainVideoID;
    videoAPI.getWorkflowPhasesJsonById(videoID).then((response) => {
      if (MiscFunctions.isUndefined(response)) return;
      if (!MiscFunctions.isNull(response.length)) {
        let phasesSegments = new Segments([]);
        response.forEach((response, i) => {
          let segmentPhase = new Segment();
          segmentPhase.assignData = response;
          phasesSegments.addSegment = segmentPhase;
        });
        setWorkflowPhases(phasesSegments);
      } else {
        setWorkflowPhases(new Segments([]));
      }
    });

    videoAPI.getWorkflowTasksJsonById(videoID).then((responses) => {
      if (MiscFunctions.isUndefined(responses)) return;
      if (!MiscFunctions.isNull(responses.length)) {
        let tasksSegments = new Segments([]);
        responses.forEach((response, i) => {
          let segmentTasks = new Segment();
          segmentTasks.assignData = response;
          tasksSegments.addSegment = segmentTasks;
        });
        setWorkflowTasks(tasksSegments);
      } else {
        setWorkflowTasks(new Segments([]));
      }
    });
  }, [UpdateSegments]);

  const [zoomLevel, setZoomLevel] = useState(1);
  const [rangeSliderValue, setRangeSliderValue] = useState([0, 1]);

  const handlingZoomScroll = (newZoomLevel, rangeValue) => {
    if (MiscFunctions.isNull(newZoomLevel)) {
      setRangeSliderValue(rangeValue);
    } else {
      setZoomLevel(newZoomLevel);
      setRangeSliderValue(rangeValue);
    }
  };

  useEffect(() => {
    setZoomLevel(zoomLevel);
    setRangeSliderValue(rangeSliderValue);
  }, [zoomLevel, rangeSliderValue]);

  const SETTINGS = {
    PlayerSidebar: props.PlayerSidebar,
    QuickOverviewPopups: props.QuickOverviewPopups,
    addComment: props.addComment,
  };

  const [containerHeight, setContainerHeight] = useState(450);
  useEffect(() => {
    function handleResize() {
      if (containerAnnotationRef.current) {
        const contentAdmin = 40;
        const commentSearch = 35;
        const commentFilter = 35;
        const SumHeight = contentAdmin + commentSearch + commentFilter;
        setContainerHeight(
          parseFloat(
            parseFloat(containerAnnotationRef.current.clientHeight + 60) -
            SumHeight
          )
        );
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isVideoLoading]);

  useEffect(() => {
    if (toastMessage !== "") {
      ToastMessages.warnMessage('Fill the required fields (Title, Content and Category).');
      setToastMessage('')
    }
  }, [toastMessage]);

  const [clickedComment, setClickedComment] = useState(null);
  const getClickedComment = (value) => {
    if (
      !MiscFunctions.isUndefined(passVideoCaption) &&
      passVideoCaption.length > 0
    ) {
      const clickComment = passVideoCaption.find((o) => o.id === value);
      setClickedComment(clickComment);
    }
  };

  useEffect(() => {
    if (
      !MiscFunctions.isUndefined(passVideoCaption) &&
      passVideoCaption.length > 0 &&
      clickedComment !== null &&
      clickedComment !== undefined
    ) {
      const clickComment = passVideoCaption.find(
        (o) => o.id === clickedComment.id
      );
      setClickedComment(clickComment);
    }
  }, [passVideoCaption, clickedComment]);

  const checkDeleteComment = (id) => {
    const updateComment = propertyCommentAnnotationArray.filter(
      (propertyCommentAnnotationArray) =>
        propertyCommentAnnotationArray.id !== id
    );
    setpropertyCommentAnnotationArray(updateComment);
  };
  useEffect(() => {
    checkDeleteComment(deleteCommentId);
  }, [deleteComment]);

  const updateComment = async () => {
    const data = await videoAPI.getVideoInteractionsJsonById(mainVideoID);
    setPassVideoCaption(data);
  };
  useEffect(() => {
    updateComment();
  }, [updateMediaFile, commentUpdated])

  const [commentCreate, setCommentCreate] = useState(false);
  const [clickPlayIconInSiderbar, setClickPlayIconInSiderbar] = useState(false);
  const [isSwimlaneClick, setIsSwimlaneClick] = useState(false);
  const [infoCard, setInfoCard] = useState(false);
  return (
    <>
      <ToastContainer />
      {!MiscFunctions.isUndefined(props.workflow) &&
        !MiscFunctions.isNull(VideoObject) &&
        SETTINGS.QuickOverviewPopups && (
          <QuickOverviewPopupsContainer
            workflow={props.workflow}
            VideoObject={VideoObject}
            showIntroPopup={showIntroPopup}
            handleCloseIntroPopup={handleCloseIntroPopup}
            ressources={ressources}
            personsGroup={personsGroup}
          />
        )}

      {isVideoLoading && (
        <div className="main_player_block flex-wrap">
          <div className="player_slider_blocks" id="video_block" onMouseOver={() => setIsSwimlaneClick(false)}
            style={{ zIndex: `${!MiscFunctions.isNull(clickedComment) ? 8 : 6}` }}>
            <div className="video_player_block">
              <div
                className="container_annotation"
                ref={containerAnnotationRef}
              >
                <CommentAnnotationContainer
                  setToastMessage={setToastMessage}
                  propertyCommentAnnotationArray={
                    propertyCommentAnnotationArray
                  }
                  companyUsers={CompanyUserObject}
                  user={UserObject}
                  passVideoCaption={passVideoCaption}
                  clickedComment={clickedComment}
                  setClickedComment={setClickedComment}
                  getClickedComment={getClickedComment}
                  deleteComment={deleteComment}
                  setDeleteComment={setDeleteComment}
                  videoID={mainVideoID}
                  player={playerRef}
                  setAddComment={setAddComment}
                  filterCategories={props.workflow.getCategories}
                  deteleCommentId={deleteCommentId}
                  setDeleteCommentId={setDeleteCommentId}
                  setUpdateMediaFile={setUpdateMediaFile}
                  updateMediaFile={updateMediaFile}
                  commentUpdated={commentUpdated}
                  setCommentUpdated={setCommentUpdated}
                  addComment={props.addComment}
                  commentCreate={commentCreate}
                  clickPlayIconInSiderbar={clickPlayIconInSiderbar}
                  setClickPlayIconInSiderbar={setClickPlayIconInSiderbar}
                  infoCard={infoCard}
                  setInfoCard={setInfoCard}
                />
                <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
              </div>
              {!MiscFunctions.isNull(playerRef.current) && (
                <TimelineGlobal
                  player={playerRef}
                  handlingZoomScroll={(newZoomLevel, rangeValue) => {
                    handlingZoomScroll(newZoomLevel, rangeValue);
                  }}
                  zoomLevel={zoomLevel}
                  rangeSliderValue={rangeSliderValue}
                  ProgressBarPosition={ProgressBarPosition}
                />
              )}
            </div>
            <div className="player_sidebar pt-3 pr-6 pl-2">
              {SETTINGS.PlayerSidebar && (
                <PlayerSidebar
                  commentCreate={commentCreate}
                  setCommentCreate={setCommentCreate}
                  setClickPlayIconInSiderbar={setClickPlayIconInSiderbar}
                  UserObject={UserObject}
                  companyUsers={CompanyUserObject}
                  player={playerRef}
                  filterCategories={filterCategories}
                  workflow={props.workflow}
                  processSteps={WorkflowPhases}
                  tasks={WorkflowTasks}
                  addComment={addComment}
                  deleteComment={deleteComment}
                  deleteCommentId={deleteCommentId}
                  UpdateSegments={() => {
                    ToggleUpdate();
                  }}
                  containerHeight={containerHeight}
                  getClickedComment={getClickedComment}
                  updateTextTracks={updateTextTracks}
                  setRessourcesUpdate={setRessourcesUpdate}
                  ressourcesUpdate={ressourcesUpdate}
                />
              )}
            </div>
          </div>
          <div
            className="container_process flex-col"
            onClick={() => {
              setIsSwimlaneClick(true)
              setClickedComment(null)
            }}
          >
            {!MiscFunctions.isNull(playerRef.current) && (
              <div>
                <WorkflowBar
                  workflowParameters={workflowParameters}
                  WorkflowPhases={WorkflowPhases}
                  WorkflowTasks={WorkflowTasks}
                  videoLabel={props.workflow.label}
                  player={playerRef}
                  ProgressBarPosition={ProgressBarPosition}
                  showIntroPopup={showIntroPopup}
                  handleOpenIntroPopup={handleOpenIntroPopup}
                  handlingZoomScroll={(newZoomLevel, rangeValue) => {
                    handlingZoomScroll(newZoomLevel, rangeValue);
                  }}
                  videoID={mainVideoID}
                  selectedVideo={props.workflow.selectedVideo}
                  zoomLevel={zoomLevel}
                  rangeSliderValue={rangeSliderValue}
                  setInfoCard={setInfoCard}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default VideoPlayer;
