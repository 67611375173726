/**
 *
 * @file SwimlaneZoomingBar.js
 * @created_date Wednesday, August 01, 2023
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This component is designed to create and manage the swimlane-style interface with zooming capabilities. It orchestrates the display of various elements such as a timeline, swimlane processes, and a segment indicator. The zoom level and scrolling behavior are controlled to ensure a seamless user experience.
 *<br/>Key functionalities - {@tutorial SwimlaneZoomingBar}
 * @module SwimlaneZoomingBar
 *
 **/

import React from "react";
import TimelineLocal from "./TimelineLocal";
import { MiscFunctions } from 'helper-functions-package';
import WorkflowPhase from "./WorkflowPhase";
import WorkflowTask from "./WorkflowTask";
import videoAPI from "../../api/videoAPI";
import VideoService from "../../services/VideoService";
import { Spinner } from "flowbite-react";
import TaskInfoCard from "./TaskInfoCard";
class SwimlaneZoomingBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      zoomLevel: this.props.zoomLevel,
      rangeSliderValue: this.props.rangeSliderValue,
      ProgressBarPosition: this.props.ProgressBarPosition,
      showHidden: false,
      clickedTasks: [],
      InfoCardTask: null,
      Position: 0,
      selectedTaskInfo: [],
      showInfoCard: false,
      clickedTaskWidth: 0,
      finalGroup: props.finalGroup,
      selectedVideo: this.props.selectedVideo,
      tools: null,
      selectedTool: null,
      showToolbox: false,
      WorkflowPhases: props.WorkflowPhases,
      videoTasks: props.videoTasks,
      currentTime: props.player.current.cache_.currentTime,
      duration: props.player.current.cache_.duration,
      isDragging: false,
      checkIfHitHour:
        props.player.current.cache_.duration / 60 >= 60 ? true : false,
      progress: 0,
      update: null,
      change: false
    };

    this.swimlaneZoomingBarRef = React.createRef();
    this.swimlaneMiddleBlockRef = React.createRef();
    this.swimlaneSegmentIndicatorRef = React.createRef();
    this.taskInfoCardRef = React.createRef();
    this.processIndicatorText = React.createRef();

    this.gi = 0;
  }

  setInfoCardTask(value) {
    this.setState({ InfoCardTask: value });
  }
  setClickedTaskWidth(value) {
    this.setState({ clickedTaskWidth: value });
  }
  setShowInfoCard(setStatus) {
    if (!MiscFunctions.isNull(setStatus)) {
      this.setState({ showInfoCard: setStatus });
      this.setState({
        showToolbox: false,
        selectedTool: null,
      });
    } else {
      this.setState(({ showInfoCard }) => ({
        showInfoCard: !showInfoCard,
      }));
    }
  }
  setTaskInfoObject(value) {
    this.setState({ selectedTaskInfo: value });
  }
  // Function to be passed to WorkflowTask-Objects to handle the global Clicked-Status-Array
  setTaskClicked(id) {
    if (MiscFunctions.isNull(id)) {
      // Array is erased if the input is null to ensure reseting of the clicked tasks
      this.setState({ clickedTasks: [] });
    } else {
      let Tasks = this.state.clickedTasks;
      // id is only added if it was not found in the array.
      if (
        MiscFunctions.isUndefined(Tasks.find((elem) => {
          return elem === id;
        }))
      ) {
        Tasks.push(id);
        this.setState({ clickedTasks: Tasks });
      }
    }
  }

  scrollingTo() {
    let unit = this.swimlaneMiddleBlockRef.current.scrollWidth;

    this.swimlaneZoomingBarRef.current.scrollTo({
      left: this.props.rangeSliderValue[0] * unit,
    });
  }

  draggableProgressBar = (e) => {
    if (!this.state.showHidden) {
      this.setState({ showHidden: true });
    }

    let processColumn = document.querySelector(".swimlane-middle-block");

    let mainVideo = document.querySelector(".medimesh-video-player");

    let timelineWidth = processColumn.clientWidth;
    mainVideo.currentTime = (e.offsetX / timelineWidth) * mainVideo.duration;

  };

  handleSelectedTool(tool) {
    this.setState({ selectedTool: tool, showToolbox: true });
  }

  componentDidUpdate(prevProps, prevState) {
    this.scrollingTo();

    if (prevProps.videoTasks !== this.props.videoTasks) {
      this.setState({ videoTasks: this.props.videoTasks });
    }
    if (prevProps.WorkflowPhases !== this.props.WorkflowPhases) {
      this.setState({ WorkflowPhases: this.props.WorkflowPhases });
      this.setState({ finalGroup: this.props.finalGroup });
    }

    if (prevState.tools !== this.state.tools) {
      this.setState({ tools: this.state.tools });
    }

    if (prevProps.finalGroup !== this.props.finalGroup) {
      this.setState({ finalGroup: this.props.finalGroup });
    }
    if (prevState.zoomLevel !== this.props.zoomLevel) {
      this.setState({ zoomLevel: this.props.zoomLevel });
      this.setShowInfoCard(false);
    }
    if (
      prevState.currentTime !== this.props.player.current.cache_.currentTime
    ) {
      this.setState({
        currentTime: this.props.player.current.cache_.currentTime,
        change: true
      });
    }
    if (prevProps.ProgressBarPosition !== this.props.ProgressBarPosition) {
      this.setState({
        ProgressBarPosition: this.props.ProgressBarPosition,
        progress: this.props.ProgressBarPosition.pos * 100,
      });
      // this.setShowInfoCard(false);
    }

    if (prevState.selectedTaskInfo !== this.state.selectedTaskInfo) {
      this.topValue = 0;
      this.state.selectedTaskInfo.getLevel === "taskgroup"
        ? (this.topValue = -83 * this.gi + 24)
        : (this.topValue = -83 * this.gi - 20);
    }

    if (prevState.checkIfHitHour !== this.state.checkIfHitHour) {
      this.setState({ checkIfHitHour: this.state.checkIfHitHour });
    }
    if (prevProps.rangeSliderValue !== this.props.rangeSliderValue) {
      this.setShowInfoCard(false);
      this.setState({ rangeSliderValue: this.props.rangeSliderValue });
    }
  }

  componentDidMount() {

    let VideoObject = new VideoService(this.state.selectedVideo);
    videoAPI.getVideoRessourcesById(VideoObject.getVideoID).then((response) => {
      let resources = response;
      let tools = resources.filter(
        (resource) =>
          resource.reference && resource.reference.category === "tool"
      );
      this.setState({ tools: tools });
    });

    this.setState({
      phases: this.state.WorkflowPhases,
      tasks: this.state.videoTasks,
    });

    const swimlaneZoomingBar = this.swimlaneZoomingBarRef.current;
    const swimlaneMiddleBlock = this.swimlaneMiddleBlockRef.current;

    if (swimlaneZoomingBar) {
      const handleScroll = (delta) => {
        this.setShowInfoCard(false); // Hide the info card during scrolling
        swimlaneZoomingBar.scrollTo({
          left: swimlaneZoomingBar.scrollLeft + delta, // Scroll horizontally by the delta amount
        });

        // Calculate the new minimum and maximum scroll positions
        const newMin = swimlaneZoomingBar.scrollLeft / swimlaneMiddleBlock.scrollWidth;
        const newMax = newMin + this.state.zoomLevel;
        this.props.handlingZoomScroll([newMin, newMax]); // Update the zoom scroll state
      };

      const onWheel = (e) => {
        if (!e.ctrlKey) return; // Only handle the event if the Ctrl key is pressed
        e.preventDefault(); // Prevent the default scrolling behavior
        handleScroll(e.deltaY); // Pass the vertical scroll amount to the handleScroll function
      };

      let touchStartX = 0; // Initialize touch start position

      const onTouchStart = (e) => {
        touchStartX = e.touches[0].clientX; // Capture the initial touch position
      };

      const onTouchMove = (e) => {
        const touchEndX = e.touches[0].clientX; // Capture the current touch position
        const deltaX = touchStartX - touchEndX; // Calculate the horizontal distance moved
        handleScroll(deltaX); // Pass the horizontal scroll amount to the handleScroll function
        touchStartX = touchEndX; // Update touch start position for continuous movement
      };

      // Attach event listeners for mouse wheel and touch events
      swimlaneZoomingBar.addEventListener("wheel", onWheel);
      swimlaneZoomingBar.addEventListener("touchstart", onTouchStart);
      swimlaneZoomingBar.addEventListener("touchmove", onTouchMove);

      return () => {
        // Remove event listeners to avoid memory leaks
        swimlaneZoomingBar.removeEventListener("wheel", onWheel);
        swimlaneZoomingBar.removeEventListener("touchstart", onTouchStart);
        swimlaneZoomingBar.removeEventListener("touchmove", onTouchMove);
      };
    }

  }

  handleSeek = (e) => {
    const video = document.querySelector(".medimesh-video-player");
    video.pause();
    const seekTime = (e.target.value / 100) * video.duration;
    this.setState({ progress: e.target.value, update: seekTime, change: false });
  };

  handleMouseUp = () => {
    this.setState({ change: true });
    const video = document.querySelector(".medimesh-video-player");
    this.setState({ currentTime: this.state.update })
    video.currentTime = (this.state.progress / 100) * video.duration;
    video.play();
  };

  updateTime = () => {
    if (MiscFunctions.isNull(this.state.update)) {
      return this.state.currentTime;
    } else {
      if (this.state.change) {
        return this.state.currentTime;
      } else {
        return this.state.update;
      }
    }
  }
  render() {
    const middleBlockWidth = (100 * 1) / this.state.zoomLevel;

    let pos = 0;
    if (!MiscFunctions.isNull(this.swimlaneMiddleBlockRef.current)) {
      pos =
        (-(
          (this.props.rangeSliderValue[0] *
            this.swimlaneMiddleBlockRef.current.scrollWidth) /
          this.swimlaneMiddleBlockRef.current.offsetWidth -
          this.state.InfoCardTask
        ) /
          this.state.zoomLevel) *
        100;
    }

    let taskRight = 0;

    taskRight =
      ((Number(this.state.selectedTaskInfo.getDuration) /
        this.props.player.current.cache_.duration) *
        100) /
      this.state.zoomLevel;

    let taskListCounter = 1;

    const splitedByDot = MiscFunctions.secondsMinuteFormat(
      this.updateTime()
    ).split(".")[0];
    const splittedByColon = splitedByDot.split(":");
    const indicatorCurrentTime =
      this.state.checkIfHitHour === true
        ? splitedByDot
        : `${splittedByColon[1]}:${splittedByColon[2]}`;

    const tooltipWidth = this.processIndicatorText;

    let rightEdgeTooltip;
    if (!MiscFunctions.isNull(tooltipWidth.current))
      rightEdgeTooltip = -(tooltipWidth.current.offsetWidth / 2) + 1;

    if (!MiscFunctions.isNull(tooltipWidth.current) && this.state.progress >= 98)
      rightEdgeTooltip = -tooltipWidth.current.offsetWidth;

    return (
      <div
        style={{
          position: "relative",
          display: "flex",
          width: "100%",
        }}
      >
        {this.state.showInfoCard &&
          <TaskInfoCard
            pos={pos}
            taskRight={taskRight}
            topValue={this.topValue}
            taskInfoCardRef={this.taskInfoCardRef}
            showToolbox={this.state.showToolbox}
            selectedTool={this.state.selectedTool}
            selectedTaskInfo={this.state.selectedTaskInfo}
            handleSelectedTool={this.handleSelectedTool} />
        }

        <div
          className="swimlane-zooming-bar-block"
          ref={this.swimlaneZoomingBarRef}
          onClick={(e) => {
            if (
              e.target.classList[0] === "swimlane-zooming-bar-block" ||
              e.target.classList[0] === "swimlane-scrollable" ||
              e.target.classList[0] === "swimlane-tasksBlock" ||
              e.target.classList[0] === "swimlane-segment-wrapper"
            ) {
              this.setShowInfoCard(false);
              this.setState({
                showToolbox: false,
                selectedTool: null,
              });
            }
          }}
        >
          <div
            className="swimlane-middle-block"
            style={{ width: `${middleBlockWidth}%` }}
            ref={this.swimlaneMiddleBlockRef}
          >
            <div>
              {this.state.progress * middleBlockWidth > 100 && (
                <div
                  className="process-indicator-text"
                  ref={this.processIndicatorText}
                  style={{
                    left: `${this.state.progress}%`,
                    translate: `${rightEdgeTooltip}px`,
                  }}
                >
                  {indicatorCurrentTime}
                </div>
              )}
              <input
                type="range"
                value={this.state.progress}
                min={0}
                max={100}
                step="0.01"
                onChange={this.handleSeek}
                onMouseUp={this.handleMouseUp}
                onTouchEnd={this.handleMouseUp}
                className="swimlane-segment-indicator-input"
              />
            </div>

            <TimelineLocal
              duration={this.props.player.current.cache_.duration}
            />
            {this.state.showHidden && <div className="hiddenBar"></div>}
            <div className="swimlane-scrollable">
              {!MiscFunctions.isNull(this.props.WorkflowPhases) &&
                !MiscFunctions.isUndefined(this.props.WorkflowPhases) && (
                  <div className="swimlane-phasesBlock">
                    {this.props.WorkflowPhases.Elements.map((elem, i) => {
                      if (elem.getLevel === "phase") {
                        return (
                          <WorkflowPhase
                            phase={elem}
                            phases={this.props.WorkflowPhases}
                            videoLength={
                              this.props.player.current.cache_.duration
                            }
                            counter={i}
                            player={this.props.player}
                            start={elem.getTimeStampSeconds}
                            key={i}
                          />
                        );
                      } else return false;
                    })}
                  </div>
                )}
              {typeof this.state.finalGroup === "object" &&
                !MiscFunctions.isNull(this.state.finalGroup) &&
                this.state.finalGroup.length < 1 && (
                  <div className="flex flex-wrap flex-col gap-2 pt-40 text-center marg">
                    <Spinner
                      color="success"
                      aria-label="loading tasks"
                      size="xl"
                    />
                  </div>
                )}
              {typeof this.state.finalGroup === "object" &&
                !MiscFunctions.isNull(this.state.finalGroup) &&
                this.state.finalGroup.map((group, i) => {
                  return group.persons.map((person, fi) => {
                    const tasks = person.tasks;
                    return (
                      <div
                        className={"swimlane-tasksBlock"}
                        style={
                          fi === 0 ? { marginTop: "5px" } : { marginTop: 0 }
                        }
                        key={fi}
                      >
                        {person.tasks.Elements.map((elem, i) => {
                          if (elem.getLevel === "taskgroup") {
                            return (
                              <WorkflowTask
                                task={elem}
                                tasks={tasks}
                                videoLength={
                                  this.props.player.current.cache_.duration
                                }
                                counter={i}
                                player={this.props.player}
                                start={elem.getTimeStampSeconds}
                                hoverParent={(state) => { }}
                                clickParent={(state) => { }}
                                SetElementClicked={(id) => {
                                  this.setTaskClicked(id);
                                }}
                                Count={taskListCounter}
                                SetTaskClicked={(value, counter) => {
                                  this.gi = counter;
                                  this.setInfoCardTask(value);
                                }}
                                setClickedTaskWidth={(value) => {
                                  this.setClickedTaskWidth(value);
                                }}
                                ElementClicked={this.state.clickedTasks}
                                selectedTaskInfo={(taskInfo) => {
                                  this.setTaskInfoObject(taskInfo);
                                }}
                                setShowInfoCard={(setStatus) => {
                                  this.setShowInfoCard(setStatus);
                                }}
                                showInfoCardStatus={this.state.showInfoCard}
                                key={i}
                                searchKeyword={this.props.searchKeyword}
                                isSearching={this.props.isSearching}
                                setInfoCard={this.props.setInfoCard}
                              />
                            );
                          } else return false;
                        })}
                        <span style={{ display: "none" }}>
                          {taskListCounter++}
                        </span>
                      </div>
                    );
                  });
                })}
              {(MiscFunctions.isNull(this.state.finalGroup) || MiscFunctions.isUndefined(this.state.finalGroup)) && (!MiscFunctions.isNull(this.props.person) && !MiscFunctions.isUndefined(this.props.persons))
                ? (<div className="workflowBar_Spinner">
                  <Spinner
                    color="success"
                    aria-label="loading tasks"
                    size="xl"
                  />
                </div>) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SwimlaneZoomingBar;
