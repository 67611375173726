/**
 * @file WorkflowBar.js
 * @created_date Wednesday, August 01, 2023
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This component, brings together various sub-components to create a comprehensive interface for managing the workflow visualization.
 *<br/>Breakdown of the component - {@tutorial WorkflowBar}
 * @module WorkflowBar
 *
 **/

import React from "react";
import SwimlaneContainer from "./SwimlaneContainer";
class WorkflowBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      zoomLevel: this.props.zoomLevel,
      rangeSliderValue: this.props.rangeSliderValue,
      searchKeyword: null,
      isSearching: false,
      sortOptions: this.props.workflowParameters.person,
      sortOption: null,
    };
  }

  handleSortOptionChange(e) {
    this.setState({ sortOption: e });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.WorkflowTasks !== this.props.WorkflowTasks) {
      this.setState({ WorkflowTasks: this.props.WorkflowTasks });
    }
    if (prevProps.zoomLevel !== this.props.zoomLevel) {
      this.setState({ zoomLevel: this.props.zoomLevel });
    }
    if (prevProps.rangeSliderValue !== this.props.rangeSliderValue) {
      this.setState({ rangeSliderValue: this.props.rangeSliderValue });
    }
  }

  render() {
    return (
      <>
        <div className="workflow-bar">
          <SwimlaneContainer
            videoLabel={this.props.videoLabel}
            WorkflowPhases={this.props.WorkflowPhases}
            WorkflowTasks={this.props.WorkflowTasks}
            zoomLevel={this.state.zoomLevel}
            player={this.props.player}
            rangeSliderValue={this.state.rangeSliderValue}
            handlingZoomScroll={(rangeValue) => {
              this.props.handlingZoomScroll(null, rangeValue);
            }}
            ProgressBarPosition={this.props.ProgressBarPosition}
            workflowParameters={this.props.workflowParameters}
            searchKeyword={this.state.searchKeyword}
            isSearching={this.state.isSearching}
            sortOption={this.state.sortOption}
            showIntroPopup={this.props.showIntroPopup}
            handleOpenIntroPopup={this.props.handleOpenIntroPopup}
            videoID={this.props.videoID}
            selectedVideo={this.props.selectedVideo}
            setInfoCard={this.props.setInfoCard}
          />
        </div>
      </>
    );
  }
}

export default WorkflowBar;
